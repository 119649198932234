import React, { useContext, useEffect } from "react";
import { useMetadata } from "./MetadataContext";

type savedSearches = {
  prospectsGrid: Record<string, string>;
  schedule: Record<string, string>;
};

type SavedSearchContextType = {
  saveSearch: (page: keyof savedSearches, searchKey: string, searchValue: string) => void;
  getSearches: (page: keyof savedSearches) => Record<string, string>;
};

const SavedSearchContext = React.createContext<SavedSearchContextType | undefined>(undefined);

const SavedSearchProvider: React.FC = ({ children }) => {
  const { draftYears } = useMetadata();
  const draftYear: string = !!draftYears?.currentDraftYear ? draftYears?.currentDraftYear?.toString() : "";

  const saveSearch = (page: keyof savedSearches, searchKey: string, searchValue: string) => {
    const existingSearchesStr = localStorage.getItem("savedSearches");
    if (existingSearchesStr === null) {
      localStorage.setItem("savedSearches", JSON.stringify({ [page]: { [searchKey]: searchValue } }));
    } else {
      const existingSearches = JSON.parse(existingSearchesStr);
      localStorage.setItem(
        "savedSearches",
        JSON.stringify({
          ...existingSearches,
          [page]: { ...existingSearches[page], [searchKey]: searchValue },
        }),
      );
    }
  };

  const saveProspectGridSearches = async (year: string) => {
    saveSearch(
      "prospectsGrid",
      "currentYearRegistered",
      JSON.stringify({
        draftYears: [{ value: parseInt(year), display: year }],
        invitationStatuses: [{ value: "registered", display: "Registered" }],
      }),
    );
    saveSearch(
      "prospectsGrid",
      "profileCompletedLast24Hrs",
      JSON.stringify({
        draftYears: [{ value: parseInt(year), display: year }],
        taskFilter: {
          taskAssigned: true,
          taskCompletedRange: "day",
          taskCompletedAfterDate: null,
          taskCompletedByDate: null,
          taskTypeIds: [4],
        },
      }),
    );
    saveSearch(
      "prospectsGrid",
      "generalQuestionnaireLast24Hrs",
      JSON.stringify({
        draftYears: [{ value: parseInt(year), display: year }],
        taskFilter: {
          taskAssigned: true,
          taskCompletedRange: "day",
          taskCompletedAfterDate: null,
          taskCompletedByDate: null,
          taskTypeIds: [1],
        },
      }),
    );
    saveSearch(
      "prospectsGrid",
      "medicalQuestionnaireLast24Hrs",
      JSON.stringify({
        draftYears: [{ value: parseInt(year), display: year }],
        taskFilter: {
          taskAssigned: true,
          taskCompletedRange: "day",
          taskCompletedAfterDate: null,
          taskCompletedByDate: null,
          taskTypeIds: [2],
        },
      }),
    );
    saveSearch(
      "prospectsGrid",
      "clubQuestionnaireLast24Hrs",
      JSON.stringify({
        draftYears: [{ value: parseInt(year), display: year }],
        taskFilter: {
          taskAssigned: true,
          taskCompletedRange: "day",
          taskCompletedAfterDate: null,
          taskCompletedByDate: null,
          taskTypeIds: [3],
        },
      }),
    );
  };

  useEffect(() => {
    if (draftYear) {
      saveProspectGridSearches(draftYear);
    }
  }, [draftYear]);

  const getSearches = (page: keyof savedSearches): Record<string, string> => {
    const existingSearchesStr = localStorage.getItem("savedSearches");
    if (existingSearchesStr === null) {
      return {};
    } else {
      return JSON.parse(existingSearchesStr)[page];
    }
  };

  return <SavedSearchContext.Provider value={{ saveSearch, getSearches }}>{children}</SavedSearchContext.Provider>;
};

const useSavedSearches = (): SavedSearchContextType => {
  const context: SavedSearchContextType | undefined = useContext(SavedSearchContext);
  if (context === undefined) {
    throw new Error(`useSavedSearches must be used within a SavedSearchProvider`);
  }
  return context;
};

export { SavedSearchProvider, useSavedSearches };
