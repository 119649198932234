import { createTheme, ThemeOptions } from "@material-ui/core/styles";
import { light } from "@material-ui/core/styles/createPalette";

const spacingValue = 8;

const spacing = (value: number) => value * spacingValue;

export const Theme: ThemeOptions = {
  spacing: spacingValue,
  typography: {
    fontFamily: '"Montserrat", san-serif',
    h4: {
      fontSize: 20,
      fontWeight: "bold",
    },
    h5: {
      fontSize: 18,
      fontWeight: "bold",
    },
    h6: {
      fontSize: 14,
      fontWeight: "bold",
      textAlign: "center",
    },
    subtitle1: {
      fontSize: 18,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 400,
      fontStyle: "italic",
    },
    body2: {
      fontSize: 14,
    },
    caption: {
      fontSize: 14,
      color: "#FFFFFF",
    },
  },
  palette: {
    ...light,
    primary: {
      main: "#08409F",
      contrastText: "#fff",
    },
    success: {
      main: "#07954D",
      contrastText: "#fff",
    },
    error: {
      main: "#AC2D1A",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontWeight: 700,
        borderRadius: "50px",
      },
      outlined: {
        border: "2px solid #08409F",
        color: "#08409F",
        background: "#FFFFFF",
      },
      outlinedSecondary: {
        color: "#AC2D1A",
        border: "1px solid #AC2D1A",
        "&:hover": {
          color: "#DB3A25",
          border: "1px solid #DB3A25",
        },
      },
      containedSecondary: {
        color: "#AC2D1A",
      },
      textSecondary: {
        color: "#AC2D1A",
      },
    },
    MuiMenuItem: {
      root: {
        alignItems: "center",
        color: "#000",
      },
    },
    MuiIconButton: {
      root: {
        color: "#000",
      },
      colorPrimary: {
        color: "#08409F",
      },
    },
    MuiBottomNavigation: {
      root: {
        width: "100%",
        borderTop: "1px solid #979797",
        position: "fixed",
        bottom: "0px",
        zIndex: 1000,
      },
    },
    MuiSvgIcon: {
      root: {
        height: 28,
      },
    },
    MuiBottomNavigationAction: {
      root: {
        background: "#ffffff",
        color: "#000000",
        "&selected": {
          color: "#08409F",
        },
      },
    },
    MuiDivider: {
      root: {
        color: "#979797",
        backgroundColor: "#979797",
        marginTop: spacing(2),
        marginBottom: spacing(2),
        height: 1,
        width: "100%",
      },
    },
    MuiInputLabel: {
      root: {
        color: "#6B7277",
        background: "#FFFFFF",
        "&$focused": {
          color: "#000000",
        },
      },
      asterisk: {
        color: "#AC2D1A",
      },
    },
    MuiPaper: {
      root: {
        boxShadow: "0 0 4px 0 rgba(4, 30, 66, 0.2)",
        color: "#212529",
      },
    },
    MuiFormGroup: {
      root: {
        marginBottom: spacing(2),
      },
    },
    MuiFormLabel: {
      root: {
        fontWeight: 500,
        color: "#000000",
        marginBottom: spacing(1),
      },
      asterisk: {
        fontWeight: 500,
        color: "#AC2D1A",
      },
    },
    MuiTextField: {
      root: {
        width: "100%",
      },
    },
    MuiTabs: {
      root: {
        flexGrow: 1,
        background: "#100E32",
        color: "#FFFFFF",
      },
    },
    MuiTab: {
      selected: {},
    },
    MuiCard: {
      root: {
        padding: spacing(2),
        borderRadius: 5,
        background: "rgba(176,187,195,0.3)",
      },
    },
    MuiTableCell: {
      head: {
        borderBottom: "1px solid #000000",
        fontWeight: "bold",
        color: "#ffffff",
        background: "#08409F",
        backgroundColor: "#08409F",
      },
      stickyHeader: {
        borderBottom: "1px solid #000000",
        fontWeight: "bold",
        color: "#ffffff",
        background: "#08409F",
        backgroundColor: "#08409F",
      },
      footer: {
        color: "#ffffff",
        borderBottom: "none",
      },
    },
    MuiTableFooter: {
      root: {
        background: "#08409F",
        color: "#ffffff",
      },
    },
    MuiTableHead: {
      root: {
        background: "#08409F",
        color: "#ffffff",
        "& .MuiIconButton-root": {
          color: "#ffffff !important",
        },
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "#FFFFFF !important",
        "&:hover": {
          color: "#FFFFFF",
        },
      },
      active: {
        color: "#FFFFFF",
      },
      icon: {
        color: "#FFFFFF !important",
      },
      iconDirectionAsc: {
        color: "#FFFFFF !important",
      },
      iconDirectionDesc: {
        color: "#FFFFFF !important",
      },
    },
    MuiStepIcon: {
      root: {
        color: "#757575 !important",
      },
      active: {
        color: "#08409F !important",
      },
      completed: {
        color: "inherit",
      },
    },
    MuiAppBar: {
      root: {
        background: "#041e42",
        color: "#FFFFFF",
      },
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: "#08409F",
        color: "#FFFFFF",
      },
    },
    //@ts-ignore
    MuiAutocomplete: {
      inputRoot: {
        background: "#dee5ed !important",
      },
    },
    //@ts-ignore
    MuiAlert: {
      root: {
        alignItems: "center",
      },
      filledSuccess: {
        backgroundColor: "#4caf50 !important",
      },
      filledInfo: {
        backgroundColor: "#2196f3 !important",
      },
      filledWarning: {
        backgroundColor: "#ff9800 !important",
      },
      filledError: {
        backgroundColor: "#f44336 !important",
      },
    },
    MuiTablePagination: {
      root: {
        float: "left",
        color: "#FFFFFF",
        borderBottom: "none",
      },
      spacer: {
        flex: "none",
      },
      selectIcon: {
        color: "#FFFFFF",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 16,
      },
    },
  },
};

export default createTheme(Theme);
