export type OrganizationDTO = {
  orgId: number;
  orgCode: string;
  orgName: string;
  league: string;
  domain: string;
  city: string;
  leagueDTO: LeagueDTO;
};

export type LeagueDTO = {
  id: number;
  name: string;
};

export const ORG_IDS = {
  MLB: 31,
  USA_BASEBALL: 33,
  TOP_PROSPECT: 35,
  MLBPA: 73,
};

export const enum PROFILE_TYPE_IDS {
  DRAFT = 1,
  SHOWCASE = 2,
  MEDICAL_STUDY = 3,
}

export const ProfileTypeNames: { [key in PROFILE_TYPE_IDS]: string } = {
  [PROFILE_TYPE_IDS.DRAFT]: "Draft",
  [PROFILE_TYPE_IDS.SHOWCASE]: "Showcase",
  [PROFILE_TYPE_IDS.MEDICAL_STUDY]: "Medical Study",
};

export type OrganizationManagementDTO = {
  id: number;
  name: string;
  inviteType: number;
  isDirty: boolean;
};
