import React, { useContext, useEffect, useState } from "react";

type LoadingContextType = {
  loading: boolean;
  setLoading: (value: boolean) => void;
  setLoadingKey(key: string, value: boolean): void;
  loadingStatus: string;
  setLoadingStatus: (value: string) => void;
};

const LoadingContext = React.createContext<LoadingContextType | undefined>(undefined);

const LoadingProvider = ({ ...props }) => {
  const [loading, setLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState("");
  const [loadingObj, setLoadingObj] = useState<{ [key: string]: boolean }>({});

  const setLoadingDefault = (value: boolean) => {
    setLoadingKey("default", value);
  };

  const setLoadingKey = (key: string, value: boolean) => {
    if (!value) {
      // remove key from loadingObj if loading is false
      setLoadingObj((prev) => {
        const newObj = { ...prev };
        delete newObj[key];
        return newObj;
      });
    } else {
      // add key to loadingObj
      setLoadingObj((prev) => {
        return { ...prev, [key]: value };
      });
    }
  };

  useEffect(() => {
    setLoading(Object.values(loadingObj).some((value) => value));
  }, [loadingObj]);

  return (
    <LoadingContext.Provider
      value={{
        loading,
        setLoading: setLoadingDefault,
        setLoadingKey,
        loadingStatus,
        setLoadingStatus,
      }}
      {...props}
    />
  );
};

const useLoading = (): LoadingContextType => {
  const context: LoadingContextType | undefined = useContext<LoadingContextType | undefined>(LoadingContext);
  if (context === undefined) {
    throw new Error(`useLoading must be used within a LoadingProvider`);
  }
  return context;
};

export { LoadingProvider, useLoading };
