export const randomString = (): string => Math.random().toString(36).substr(2, 9);

export const isNumber = (value: string | number): boolean => typeof value == "number";

export const toLower = (str: string | number): string | number => {
  if (str) {
    if (isNumber(str)) {
      return str;
    } else {
      return str.toString().toLowerCase();
    }
  } else {
    return "";
  }
};

export const includes = (data: string | number | undefined | null, test: string): boolean =>
  !!data && !!test && toLower(data).toString().includes(test.toString().toLowerCase());

const MoneyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

export const toMoney = (value: number): string => {
  if (value !== null && value !== undefined) {
    return MoneyFormatter.format(value);
  } else {
    return "";
  }
};
