import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import React, { useEffect } from "react";
import AuthClient from "./AuthClient";
import ClipsClient from "./ClipsClient";
import { getItem } from "../util/LocalStorageUtil";

interface HttpInterceptorProps {
  children: React.ReactNode;
}

type TokenType = { accessToken?: string; idToken?: string };

export const getAuthTokens = (): TokenType => {
  let tokens: TokenType | undefined = undefined;
  const oktaTokenStr = window.localStorage.getItem("okta-token-storage");
  if (!!oktaTokenStr) {
    const oktaToken = JSON.parse(oktaTokenStr);
    if (!!oktaToken.accessToken?.accessToken && !!oktaToken.idToken?.idToken) {
      tokens = {
        accessToken: oktaToken.accessToken?.accessToken,
        idToken: oktaToken.idToken?.idToken,
      };
    }
  }
  //coming from mobile app stored in localStorage
  if (!tokens) {
    if (window.isNativeApp) {
      const accessToken = window.localStorage.getItem("accessToken");
      const idToken = window.localStorage.getItem("idToken");
      if (!!accessToken) {
        tokens = { accessToken, idToken: !!idToken ? idToken : accessToken } as TokenType;
      } else {
        tokens = {} as TokenType;
      }
    } else {
      tokens = {} as TokenType;
    }
  }
  return tokens;
};

const HttpInterceptor: React.FC<HttpInterceptorProps> = ({ children }) => {
  const createInterceptors = () => {
    // DPL interceptors
    axiosRetry(AuthClient, {
      retries: 3, //attempts to retry
      retryCondition: (error) => {
        console.error("Error fetching from API will retry...");
        return !!error?.response && error.response.status === 401;
      }, // only retry on unauthenticated errors to prevent retrying 500s unless that is wanted
      retryDelay: (retryCount) => retryCount * 1000, // wait 1 second between retrys
    });

    AuthClient.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        // Do something before request is sent
        const { accessToken, idToken } = getAuthTokens();
        config.headers = config.headers ?? {};
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
        if (idToken) {
          config.headers["x-id-token"] = idToken;
        }
        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      },
    );

    AuthClient.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      (error: AxiosError) => {
        if (error.response?.status === 401) {
          if (error.response.data.message === "token_expired") {
            console.log("token expired, refreshing");
          }
        }
        return Promise.reject(error);
      },
    );

    // clips interceptors
    axiosRetry(ClipsClient, {
      retries: 3, //attempts to retry
      retryCondition: (error) => {
        console.error("Error fetching from API will retry...");
        return !!error?.response && error.response.status === 401;
      }, // only retry on unauthenticated errors to prevent retrying 500s unless that is wanted
      retryDelay: (retryCount) => retryCount * 1000, // wait 1 second between retrys
    });

    ClipsClient.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        const accessToken = getItem("clipsAccessToken");
        config.headers = config.headers ?? {};
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  };

  useEffect(() => {
    createInterceptors();
  }, []);

  return <>{children}</>;
};

export default HttpInterceptor;
