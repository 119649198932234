import { useTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, useHistory, useLocation } from "react-router-dom";
import SystemAlerts from "../components/alerts/SystemAlerts";
import BlockingPrompt from "../components/control/BlockingPrompt";
import { useAuth } from "../context/AuthContext";

const AuthenticatedApp = React.lazy(() => import("./AuthenticatedApp"));
const UnauthenticatedApp = React.lazy(() => import("./UnauthenticatedApp"));

type AppSwitchProps = {
  loggedIn: boolean;
};

const BaseUrl = "/";

const AppSwitch: React.FC<AppSwitchProps> = ({ loggedIn }) => {
  const location = useLocation();
  const history = useHistory();
  const [deepLink, setDeepLink] = useState(BaseUrl);

  useEffect(() => {
    if (!loggedIn) {
      if (location.pathname !== BaseUrl) {
        setDeepLink(location.pathname + location.search);
      }
    } else if (deepLink !== BaseUrl) {
      history.push(deepLink);
      setDeepLink(BaseUrl);
    }
  }, [loggedIn, deepLink]);

  return loggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />;
};

const App: React.FC = () => {
  const { user } = useAuth();
  const theme = useTheme();
  const loggedIn = !!user?.orgId;
  return (
    <React.Suspense fallback={<div />}>
      <Helmet>
        <title>Prospect Link</title>
      </Helmet>
      <Router
        getUserConfirmation={(message, callback) => {
          const container = document.createElement("div");
          document.body.appendChild(container);

          const onClose = (value: boolean) => {
            ReactDOM.unmountComponentAtNode(container);
            callback(value);
          };

          ReactDOM.render(
            <ThemeProvider theme={theme}>
              <BlockingPrompt blockFunc={onClose} message={message} />
            </ThemeProvider>,
            container,
          );
        }}
      >
        <AppSwitch loggedIn={loggedIn} />
      </Router>
      <SystemAlerts />
    </React.Suspense>
  );
};

export default App;
