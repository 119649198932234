import axios, { AxiosInstance } from "axios";

const BASE_URL = window.MLBBest.envVariables.BASE_URL;

const AuthClient: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

export default AuthClient;
