import { Theme } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import LightLogo from "../assets/DPL.svg";
import DarkLogo from "../assets/DPL_White.svg";
import darkTheme from "../util/DarkTheme";
import lightTheme from "../util/LightTheme";
import { getItem, setItem } from "../util/LocalStorageUtil";

type DplThemeContextType = {
  theme: Theme;
  appLogo: string;
  isDarkMode: boolean;
  toggleMode: () => void;
};

const DplThemeContextType = React.createContext<DplThemeContextType | undefined>(undefined);

const localStorageStr = "dpl-theme";

const DplThemeProvider: React.FC = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [theme, setTheme] = useState<Theme>(lightTheme);
  const [appLogo, setAppLogo] = useState<string>(LightLogo);

  const toggleMode = () => {
    setIsDarkMode(!isDarkMode);
    setItem(localStorageStr, isDarkMode ? "light" : "dark");
  };

  useEffect(() => {
    const body = document.body;
    if (isDarkMode) {
      setTheme(darkTheme);
      setAppLogo(DarkLogo);
      body.style.background = darkTheme.palette.background.default;
      window.darkMode = true;
    } else {
      setTheme(lightTheme);
      setAppLogo(LightLogo);
      body.style.background = lightTheme.palette.background.default;
      window.darkMode = false;
    }
  }, [isDarkMode]);

  useEffect(() => {
    const storedValue = getItem(localStorageStr);
    if (!!storedValue) {
      setIsDarkMode(storedValue === "dark");
    } else {
      const systemDarkMode = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
      setIsDarkMode(!!window.darkMode || systemDarkMode);
    }
  }, []);

  return (
    <DplThemeContextType.Provider value={{ isDarkMode, appLogo, theme, toggleMode }}>
      {children}
    </DplThemeContextType.Provider>
  );
};

const useDplTheme = (): DplThemeContextType => {
  const context: DplThemeContextType | undefined = useContext(DplThemeContextType);
  if (context === undefined) {
    throw new Error(`useDplTheme must be used within a DplThemeProvider`);
  }
  return context;
};

export { DplThemeProvider, useDplTheme };
