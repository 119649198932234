import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useEffect, useMemo, useState } from "react";
import { AuthProvider } from "./AuthContext";
import { useDplTheme } from "./DplThemeContext";
import { LoadingProvider } from "./LoadingContext";
import { SiteAlertProvider } from "./SiteAlertContext";
import { DplAlertProvider } from "./DplAlertContext";
import { SavedSearchProvider } from "./SavedSearchContext";
import { MetadataProvider } from "./MetadataContext";
import HttpInterceptor from "../clients/HttpInterceptor";
import "video-react/dist/video-react.css";
import { OktaAuth } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";

const oktaConfig = window.MLBBest.envVariables.OKTA_CONFIG;

const Providers: React.FC = ({ children }) => {
  const { theme } = useDplTheme();
  const oktaAuth = useMemo(() => new OktaAuth(oktaConfig), []);

  const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
    window.location.replace(originalUri);
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <SiteAlertProvider>
          <LoadingProvider>
            <AuthProvider oktaAuth={oktaAuth}>
              <HttpInterceptor>
                <DplAlertProvider>
                  <MetadataProvider>
                    <SavedSearchProvider>
                      <ThemeProvider theme={theme}>{children}</ThemeProvider>
                    </SavedSearchProvider>
                  </MetadataProvider>
                </DplAlertProvider>
              </HttpInterceptor>
            </AuthProvider>
          </LoadingProvider>
        </SiteAlertProvider>
      </MuiPickersUtilsProvider>
    </Security>
  );
};

export default Providers;
