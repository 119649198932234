import { datadogRum } from "@datadog/browser-rum";
import "@fortawesome/fontawesome-free/css/all.css";
import "core-js/stable";
import React from "react";
import { render } from "react-dom";
import "regenerator-runtime/runtime";
import "typeface-montserrat";
import App from "./apps/App";
import Providers from "./context";
import { DplThemeProvider } from "./context/DplThemeContext";
import "./index.css";

const env = window.MLBBest.envVariables.ENV;

datadogRum.init({
  applicationId: "d2aa1c5b-0051-4821-8cbb-ae6806ece509",
  clientToken: "puba26e3bdb3360aff674a6005b327db915",
  site: "us5.datadoghq.com",
  service: "prospect-link-domestic-(boc/club)",
  env: env,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: 100,
  premiumSampleRate: 50,
  trackInteractions: true,
  defaultPrivacyLevel: "mask-user-input",
});

datadogRum.startSessionReplayRecording();
render(
  <DplThemeProvider>
    <Providers>
      <App />
    </Providers>
  </DplThemeProvider>,
  document.getElementById("dpl-prospect-app"),
);
