import axios, { AxiosInstance } from "axios";
import { ClipsSearchResponse } from "../types/Clips";

const BASE_URL = window.MLBBest.envVariables.CLIPS_URL;

const ClipsClient: AxiosInstance = axios.create({
  baseURL: BASE_URL,
});

export const getClipsFromDplId = async (dplId: number, pageNum: number): Promise<ClipsSearchResponse> =>
  (await ClipsClient.post(`/metadata/search?pageNumber=${pageNum}`, { dplId })).data;

export default ClipsClient;
