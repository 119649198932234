import { createTheme, ThemeOptions } from "@material-ui/core/styles";
import { dark } from "@material-ui/core/styles/createPalette";

const spacingValue = 8;

const spacing = (value: number) => value * spacingValue;

const primary = "#3C77D6";
const link = "#8AB4F8";
const red = "#AC2D1A";

export const Theme: ThemeOptions = {
  spacing: spacingValue,
  typography: {
    fontFamily: '"Montserrat", san-serif',
    h1: {
      color: dark.text.primary,
    },
    h2: {
      color: dark.text.primary,
    },
    h3: {
      color: dark.text.primary,
    },
    body1: {
      color: dark.text.primary,
    },
    h4: {
      fontSize: 20,
      fontWeight: "bold",
      color: dark.text.primary,
    },
    h5: {
      fontSize: 18,
      fontWeight: "bold",
      color: dark.text.primary,
    },
    h6: {
      fontSize: 14,
      fontWeight: "bold",
      textAlign: "center",
      color: dark.text.primary,
    },
    subtitle1: {
      fontSize: 18,
      color: dark.text.primary,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 400,
      fontStyle: "italic",
      color: dark.text.primary,
    },
    body2: {
      fontSize: 14,
      color: dark.text.primary,
    },
    caption: {
      fontSize: 14,
      color: dark.text.primary,
    },
  },
  palette: {
    ...dark,
    primary: {
      main: primary,
      contrastText: dark.text.primary,
    },
    success: {
      main: "#07954D",
      contrastText: dark.text.primary,
    },
    error: {
      main: "#AC2D1A",
      contrastText: dark.text.primary,
    },
  },
  overrides: {
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: `1px solid ${dark.text.primary}`,
        },
        "&:hover:not($disabled):before": {
          borderBottom: `2px solid ${dark.text.primary}`,
        },
      },
    },
    MuiInputBase: {
      root: {
        background: dark.background.paper,
        text: dark.text.primary,
        borderColor: primary,
      },
      adornedEnd: {
        borderColor: primary,
      },
    },
    MuiButton: {
      root: {
        fontWeight: 700,
        borderRadius: "50px",
      },
      outlined: {
        border: `2px solid ${primary}`,
        color: dark.text.primary,
      },
      outlinedPrimary: {
        color: dark.text.primary,
      },
      text: {
        color: dark.text.primary,
      },
    },
    MuiMenuItem: {
      root: {
        alignItems: "center",
        color: dark.text.primary,
      },
    },
    MuiIconButton: {
      root: {
        color: dark.text.primary,
      },
      colorPrimary: {
        color: primary,
      },
    },
    MuiBottomNavigationAction: {
      root: {
        background: dark.background.paper,
        color: dark.text.primary,
        "&selected": {
          color: primary,
        },
      },
    },
    MuiDivider: {
      root: {
        color: dark.divider,
        backgroundColor: dark.divider,
        marginTop: spacing(2),
        marginBottom: spacing(2),
        height: 1,
        width: "100%",
      },
    },
    MuiInputLabel: {
      root: {
        color: dark.text.primary,
        background: "transparent",
        "&$focused": {
          color: dark.action.active,
        },
      },
      asterisk: {
        color: red,
      },
    },
    MuiSvgIcon: {
      root: {
        height: 28,
      },
    },
    MuiPaper: {
      root: {
        boxShadow: "0 0 4px 0 rgba(4, 30, 66, 0.2)",
        color: dark.text.primary,
        background: dark.background.paper,
      },
    },
    MuiFormGroup: {
      root: {
        marginBottom: spacing(2),
      },
    },
    MuiFormLabel: {
      root: {
        fontWeight: 500,
        color: dark.text.primary,
        marginBottom: spacing(1),
        zIndex: 100,
      },
      asterisk: {
        fontWeight: 500,
        color: red,
      },
    },
    MuiTextField: {
      root: {
        marginBottom: spacing(2),
        width: "100%",
        background: dark.background.paper,
        color: dark.text.primary,
      },
    },
    MuiTabs: {
      root: {
        flexGrow: 1,
        background: dark.background.default,
        color: dark.text.primary,
      },
    },
    MuiTab: {
      selected: {},
    },
    MuiCard: {
      root: {
        padding: spacing(2),
        borderRadius: 5,
        background: "rgba(176,187,195,0.3)",
      },
    },
    MuiTableCell: {
      head: {
        borderBottom: `1px solid ${dark.text.primary}`,
        fontWeight: "bold",
        color: dark.text.primary,
        background: primary,
        backgroundColor: primary,
      },
      stickyHeader: {
        borderBottom: `1px solid ${dark.text.primary}`,
        fontWeight: "bold",
        color: dark.text.primary,
        background: primary,
        backgroundColor: primary,
      },
      footer: {
        color: dark.text.primary,
        borderBottom: "none",
      },
    },
    MuiTableFooter: {
      root: {
        background: primary,
        color: dark.text.primary,
      },
    },
    MuiTableHead: {
      root: {
        background: primary,
        color: dark.text.primary,
        "& .MuiIconButton-root": {
          color: "#ffffff !important",
        },
      },
    },
    MuiTableSortLabel: {
      root: {
        color: `${dark.text.primary} !important`,
        "&:hover": {
          color: dark.text.primary,
        },
      },
      active: {
        color: "#FFFFFF",
      },
      icon: {
        color: `${dark.text.primary} !important`,
      },
      iconDirectionAsc: {
        color: `${dark.text.primary} !important`,
      },
      iconDirectionDesc: {
        color: `${dark.text.primary} !important`,
      },
    },
    MuiStepIcon: {
      root: {
        color: "#757575 !important",
      },
      active: {
        color: "#08409F !important",
      },
      completed: {
        color: "inherit",
      },
    },
    MuiAppBar: {
      root: {
        background: "#041e42",
        color: dark.text.primary,
      },
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: primary,
        color: dark.text.primary,
      },
    },
    //@ts-ignore
    MuiAutocomplete: {
      inputRoot: {
        background: `${dark.background.paper} !important`,
      },
    },
    //@ts-ignore
    MuiAlert: {
      root: {
        alignItems: "center",
      },
      filledSuccess: {
        backgroundColor: "#4caf50 !important",
      },
      filledInfo: {
        backgroundColor: "#2196f3 !important",
      },
      filledWarning: {
        backgroundColor: "#ff9800 !important",
      },
      filledError: {
        backgroundColor: "#f44336 !important",
      },
    },
    MuiTablePagination: {
      root: {
        float: "left",
        color: dark.text.primary,
        borderBottom: "none",
      },
      spacer: {
        flex: "none",
      },
      selectIcon: {
        color: dark.text.primary,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 16,
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        "&.Mui-checked": {
          color: link,
        },
      },
    },
    MuiRadio: {
      colorPrimary: {
        "&.Mui-checked": {
          color: link,
        },
      },
    },
  },
};

export default createTheme(Theme);
