export type UserDetails = {
  id: number;
  activeProspectId?: number;
  firstName: string;
  lastName: string;
  email: string;
  userRole: string[];
  orgCode: string;
  orgId: number;
  statsOrgId: number;
  orgName: string;
  stale: boolean;
  onboarding: boolean;
  firstLogin: boolean;
  profileTypeId: number;
};

export const UserRoles = {
  PROSPECT_BOC_ADMIN: "prospect-boc-admin",
  PROSPECT_MEDIA_REVIEWER: "prospect-media-reviewer",
  PROSPECT_TASK_MGMNT: "prospect-task-mngmnt",
  PROSPECT_TAG_MANAGER: "prospect-tag-manager",
  PROSPECT_CLUB_ADMIN: "prospect-club-admin",
  PROSPECT_BOC_CLUB_ADMIN: "prospect-boc-club-admin",
  PROSPECT_BOC_ADMIN_TOP_PROSPECT: "prospect-boc-admin-top-prospect",
  PROSPECT_QUESTIONNAIRE_AUTHOR: "prospect-questionnaire-author",
  PROSPECT_QUESTIONNAIRE_ADMIN: "prospect-questionnaire-admin",
  PROSPECT_QUESTIONNAIRE_AUTHOR_ADVANCED: "prospect-questionnaire-author-advanced",
  PROSPECT_VIDEO_TECHNICIAN: "prospect-video-technician",
  PROSPECT_WAIVER_CREATOR: "prospect-waiver-creator",
  PROSPECT_EVENT_ADMIN: "prospect-event-admin",
  PROSPECT_EVENT_APPROVER: "prospect-event-approver",
  PROSPECT_EVENT_EDITOR: "prospect-event-editor",
  PROSPECT_EVENT_DATA_VIEWER: "prospect-event-data-viewer",
  PROSPECT_SCHEDULE_ADMIN: "prospect-schedule-admin",
  PROSPECT_SCHEDULE_VIEWER: "prospect-schedule-viewer",
  PROSPECT_SCHEDULE_MASTER: "prospect-schedule-master",
  PROSPECT_VIEW_MEDICAL: "prospect-view-medical",
  PROSPECT_SCOUT: "prospect-scout",
  PROSPECT_LEAGUE_STUDY_ADMIN: "prospect-league-study-admin",
  PROSPECT_TEAM_STUDY_ADMIN: "prospect-team-study-admin",
  PROSPECT_TEAM_STUDY_STAFF: "prospect-team-study-staff",
  PROSPECT_TEAM_STUDY_VIEWER: "prospect-team-study-viewer",
  PROSPECT_STUDY_SUPER_ADMIN: "prospect-study-super-admin",
  PROSPECT_SCHEDULE_VIDEO_TECH: "prospect-schedule-video-tech",
};

export type OrgUserDTO = {
  userId: number;
  name: string;
};
