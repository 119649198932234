import { SurveyStatusId } from "../types/Surveys";

export const SurveyConstants = {
  DRAFT_MODE: 1 as SurveyStatusId,
  REVIEW_IN_PROGRESS: 2 as SurveyStatusId,
  PENDING_CLUB_FINALIZATION: 3 as SurveyStatusId,
  FINALIZED: 4 as SurveyStatusId,
  DEACTIVATED: 5 as SurveyStatusId,
  ACTIVE: 6 as SurveyStatusId,
};
