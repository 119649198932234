import { addDays } from "date-fns";
import React, { useEffect, useState } from "react";
import {
  getClubAppInformation,
  getCountries,
  getDraftYears,
  getGameCancellationReasons,
  getGameParticipationLookup,
  getInvitedBy,
  getLocales,
  getPositions,
  getPregameActivities,
  getRelativeTypes,
  getRsvpStatusOptions,
  getScheduleWeatherIcons,
  getSchoolTypes,
  getSportLevels,
  getSportTypes,
  getStates,
  getStudyStatusOptions,
  getSurveyStatuses,
  getSystemSetting,
  getTags,
  getTaskTypesFilterOptions,
} from "../clients/DPLClient";
import AlertConstants from "../constants/AlertConstants";
import { FIRST_DAY_OF_DRAFT } from "../constants/SystemSettingsConstants";
import { LocaleDTO } from "../types/Language";
import {
  AgeStatus,
  AppInformationDTO,
  Country,
  DraftIdStatus,
  DraftYears,
  GameCancellationReasonDTO,
  GameParticipationDTO,
  Handedness,
  HasCommentOption,
  InvitationStatus,
  InvitedBy,
  Positions,
  PregameActivityDTO,
  ProfileCompleteOption,
  ProspectMedicalRequestStatus,
  RsvpStatusDTO,
  SchoolTypeData,
  States,
  SubscriptionStatus,
  SystemSetting,
  TagResponse,
  tagStatusOption,
} from "../types/Metadata";
import { ProspectRelativeSportLevel, ProspectRelativeSportType, ProspectRelativeType } from "../types/Prospects";
import { WeatherIcon } from "../types/Schedule";
import { StudyStatusDTO } from "../types/Study";
import { SurveyStatus, TaskTypeDTO } from "../types/Surveys";
import { ValueDisplay } from "../types/ValueDisplay";
import { useAuth } from "./AuthContext";
import { useSiteAlerts } from "./SiteAlertContext";

type MetadataContextType = {
  draftYears: DraftYears | null;
  countries: Country[] | null;
  states: States | null;
  positions: Positions | null;
  invitedBy: InvitedBy[];
  tags: TagResponse[];
  schoolTypes: SchoolTypeData[];
  invitationStatuses: InvitationStatus[];
  draftIdStatuses: DraftIdStatus[];
  ageStatuses: AgeStatus[];
  prospectMedicalRequestStatuses: ProspectMedicalRequestStatus[];
  hasCommentOptions: HasCommentOption[];
  profileCompleteOptions: ProfileCompleteOption[];
  tagStatusOptions: tagStatusOption[];
  handedness: Handedness[];
  subscriptionStatuses: SubscriptionStatus[];
  draftDate: Date | null;
  surveyStatuses: SurveyStatus[];
  taskTypes: TaskTypeDTO[];
  locales: LocaleDTO[];
  prospectRelativeTypes: ProspectRelativeType[];
  prospectRelativeSportTypes: ProspectRelativeSportType[];
  prospectRelativeSportLevels: ProspectRelativeSportLevel[];
  pregameActivityOptions: ValueDisplay[];
  gameParticipationOptions: ValueDisplay[];
  gameCancellationOptions: ValueDisplay[];
  rsvpStatusOptions: RsvpStatusDTO[];
  timezoneOptions: ValueDisplay[];
  clubAppInformation: AppInformationDTO | null;
  studyStatusOptions: StudyStatusDTO[];
  iconMap: Record<number, WeatherIcon> | null;
};

const MetadataContent = React.createContext<MetadataContextType | undefined>(undefined);

const invitationStatuses: InvitationStatus[] = [
  {
    value: "undeliverable",
    name: "Undeliverable",
  },
  {
    value: "pending",
    name: "Pending",
  },
  {
    value: "registered",
    name: "Registered",
  },
];

const draftIdStatuses: DraftIdStatus[] = [
  {
    id: 3,
    value: "pending",
    name: "Pending",
  },
  {
    id: 1,
    value: "eligible",
    name: "Eligible",
  },
  {
    id: 2,
    value: "ineligible",
    name: "Ineligible",
  },
  {
    id: 6,
    value: "removed",
    name: "Removed",
  },
  {
    id: 9,
    value: "insufficient",
    name: "Insufficient",
  },
];

const ageStatuses: AgeStatus[] = [
  { value: 1, name: "21 or Older As Of Now" },
  { value: 2, name: "21 or Older As Of Aug 1" },
];

const prospectMedicalRequestStatuses: ProspectMedicalRequestStatus[] = [
  {
    id: 1,
    value: "pending",
    name: "Pending",
  },
  {
    id: 2,
    value: "closed",
    name: "Closed",
  },
  {
    id: 3,
    value: "declined",
    name: "Declined",
  },
];

const handedness: Handedness[] = [
  {
    id: "L",
    name: "Left",
  },
  {
    id: "R",
    name: "Right",
  },
  {
    id: "S",
    name: "Switch",
  },
];

export const subscriptionStatuses: SubscriptionStatus[] = [
  {
    id: "subscribed",
    name: "Prospects I Am Subscribed To",
  },
  {
    id: "unsubscribed",
    name: "Prospects I Am Not Subscribed To",
  },
];
export const hasCommentOptions: HasCommentOption[] = [
  {
    name: "Has Comment",
    value: true,
  },
  {
    name: "Does Not Have Comment",
    value: false,
  },
];
export const tagStatusOptions: tagStatusOption[] = [
  {
    name: "Active",
    value: true,
  },
  {
    name: "Archived",
    value: false,
  },
];
export const profileCompleteOptions: ProfileCompleteOption[] = [
  {
    name: "Complete",
    value: true,
  },
  {
    name: "Incomplete",
    value: false,
  },
];

const MetadataProvider: React.FC = ({ ...props }) => {
  const { isLoggedIn } = useAuth();
  const { addAlert } = useSiteAlerts();
  const [draftYears, setDraftYears] = useState<DraftYears | null>(null);
  const [countries, setCountries] = useState<Country[] | null>(null);
  const [states, setStates] = useState<States | null>(null);
  const [positions, setPositions] = useState<Positions | null>(null);
  const [invitedBy, setInvitedBy] = useState<InvitedBy[]>([]);
  const [tags, setTags] = useState<TagResponse[]>([]);
  const [schoolTypes, setSchoolTypes] = useState<SchoolTypeData[]>([]);
  const [draftDate, setDraftDate] = useState<Date | null>(null);
  const [surveyStatuses, setSurveyStatuses] = useState<SurveyStatus[]>([]);
  const [taskTypes, setTaskTypes] = useState<TaskTypeDTO[]>([]);
  const [locales, setLocales] = useState<LocaleDTO[]>([]);
  const [prospectRelativeTypes, setProspectRelativeTypes] = useState<ProspectRelativeType[]>([]);
  const [prospectRelativeSportTypes, setProspectRelativeSportTypes] = useState<ProspectRelativeSportType[]>([]);
  const [prospectRelativeSportLevels, setProspectRelativeSportLevels] = useState<ProspectRelativeSportLevel[]>([]);
  const [pregameActivityOptions, setPregameActivityOptions] = useState<ValueDisplay[]>([]);
  const [gameParticipationOptions, setGameParticipationOptions] = useState<ValueDisplay[]>([]);
  const [gameCancellationOptions, setGameCancellationOptions] = useState<ValueDisplay[]>([]);
  const [rsvpStatusOptions, setRsvpStatusOptions] = useState<RsvpStatusDTO[]>([]);
  const [timezoneOptions, setTimezoneOptions] = useState<ValueDisplay[]>([]);
  const [clubAppInformation, setClubAppInformation] = useState<AppInformationDTO | null>(null);
  const [studyStatusOptions, setStudyStatusOptions] = useState<StudyStatusDTO[]>([]);
  const [iconMap, setIconMap] = useState<Record<number, WeatherIcon> | null>(null);

  const getDraftYearsData = async () => {
    const result: DraftYears = await getDraftYears();
    setDraftYears(result);
  };

  const getCountryData = async () => {
    const results: Country[] = await getCountries();
    setCountries(results);
  };

  const getStatesData = async () => {
    const result: States = await getStates();
    setStates(result);
  };

  const getPositionsData = async () => {
    const result: Positions = await getPositions();
    setPositions(result);
  };

  const getInvitedByData = async () => {
    const result: InvitedBy[] = await getInvitedBy();
    setInvitedBy(result);
  };

  const getTagsData = async () => {
    const result: TagResponse[] = await getTags();
    setTags(result);
  };

  const getSchoolTypesData = async () => {
    const result: SchoolTypeData[] = await getSchoolTypes();
    setSchoolTypes(result);
  };

  const getDraftDate = async () => {
    const result: SystemSetting = await getSystemSetting(FIRST_DAY_OF_DRAFT);
    let date = new Date(result.val);
    // add 1 day to offset timezone
    date = addDays(date, 1);
    setDraftDate(date);
  };

  const getSurveyStatusData = async () => {
    const result: SurveyStatus[] = await getSurveyStatuses();
    setSurveyStatuses(result);
  };

  const getTaskTypeData = async () => {
    const result: TaskTypeDTO[] = await getTaskTypesFilterOptions();
    setTaskTypes(result);
  };

  const getLocalesData = async () => {
    const result: LocaleDTO[] = await getLocales();
    setLocales(result);
  };

  const getRelativeTypesData = async () => {
    const result: ProspectRelativeType[] = await getRelativeTypes();
    setProspectRelativeTypes(result);
  };

  const getRelativeSportTypesData = async () => {
    const result: ProspectRelativeSportType[] = await getSportTypes();
    setProspectRelativeSportTypes(result);
  };

  const getRelativeSportLevelsData = async () => {
    const result: ProspectRelativeSportLevel[] = await getSportLevels();
    setProspectRelativeSportLevels(result);
  };

  const getPregameActivityData = async () => {
    const results: PregameActivityDTO[] = await getPregameActivities();
    const options: ValueDisplay[] = results.map((dto) => ({
      value: dto.id,
      display: dto.name,
    }));
    setPregameActivityOptions(options);
  };

  const getGameParticipationData = async () => {
    const results: GameParticipationDTO[] = await getGameParticipationLookup();
    const options: ValueDisplay[] = results.map((dto) => ({
      value: dto.id,
      display: dto.name,
    }));
    setGameParticipationOptions(options);
  };

  const getGameCancellationData = async () => {
    const results: GameCancellationReasonDTO[] = await getGameCancellationReasons();
    const options: ValueDisplay[] = results.map((dto) => ({
      value: dto.id,
      display: dto.name,
    }));
    setGameCancellationOptions(options);
  };

  const checkSiteStatus = async () => {
    const siteAlertSetting: SystemSetting = await getSystemSetting("SiteAlert");
    if (siteAlertSetting.val) {
      addAlert({
        type: AlertConstants.WARNING,
        text: siteAlertSetting.val,
        manualClose: true,
      });
    }
  };

  const getRsvpStatusData = async () => {
    const results: RsvpStatusDTO[] = await getRsvpStatusOptions();
    setRsvpStatusOptions(results);
  };

  const getTimezoneOptions = async () => {
    const timezones = (Intl as any).supportedValuesOf && (Intl as any).supportedValuesOf("timeZone");
    setTimezoneOptions(timezones.map((timezone: string) => ({ display: timezone, value: timezone })));
  };

  const fetchClubAppInformation = async () => {
    const info: AppInformationDTO = await getClubAppInformation();
    setClubAppInformation(info);
  };

  const getStudyStatusData = async () => {
    const results: StudyStatusDTO[] = await getStudyStatusOptions();
    setStudyStatusOptions(results);
  };

  const getWeatherIconMap = async () => {
    const results: WeatherIcon[] = await getScheduleWeatherIcons();
    const newIconMap = results.reduce((acc: Record<number, WeatherIcon>, icon: WeatherIcon) => {
      acc[icon.iconCode] = icon;
      return acc;
    }, {});
    setIconMap(newIconMap);
  };

  useEffect(() => {
    if (isLoggedIn) {
      getDraftYearsData();
      getCountryData();
      getStatesData();
      getPositionsData();
      getInvitedByData();
      getTagsData();
      getSchoolTypesData();
      getDraftDate();
      getSurveyStatusData();
      getTaskTypeData();
      getLocalesData();
      getRelativeTypesData();
      getRelativeSportTypesData();
      getRelativeSportLevelsData();
      getPregameActivityData();
      getGameParticipationData();
      getGameCancellationData();
      checkSiteStatus();
      getRsvpStatusData();
      getTimezoneOptions();
      fetchClubAppInformation();
      getStudyStatusData();
      getWeatherIconMap();
    }
  }, [isLoggedIn]);

  return (
    <MetadataContent.Provider
      value={{
        draftYears,
        countries,
        states,
        positions,
        invitedBy,
        tags,
        schoolTypes,
        invitationStatuses,
        draftIdStatuses,
        ageStatuses,
        prospectMedicalRequestStatuses,
        handedness,
        hasCommentOptions,
        profileCompleteOptions,
        tagStatusOptions,
        subscriptionStatuses,
        draftDate,
        surveyStatuses,
        taskTypes,
        locales,
        prospectRelativeTypes,
        prospectRelativeSportTypes,
        prospectRelativeSportLevels,
        pregameActivityOptions,
        gameParticipationOptions,
        gameCancellationOptions,
        rsvpStatusOptions,
        timezoneOptions,
        clubAppInformation,
        studyStatusOptions,
        iconMap,
      }}
      {...props}
    />
  );
};

const useMetadata = (): MetadataContextType => {
  const context: MetadataContextType | undefined = React.useContext<MetadataContextType | undefined>(MetadataContent);
  if (context === undefined) {
    throw new Error(`useMetadata must be used within a MetadataProvider`);
  }
  return context;
};

export { MetadataProvider, useMetadata };
